import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from 'src/app/core/services/base.service';
import {UrlConstant} from '../constant/url.class';
import {StorageService} from '../../../core/services/storage.service';
import {STORAGE_NAME} from '../../../core/constant/system.constants';
import {UserLogin} from '../../../shared/model/user-login';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private decodedAccessToken: any;
  private refreshTokenTimeout;
  readonly baseUrl = UrlConstant.API_VERSION;

  // Đăng nhập hệ thống
  public login(param: any): Observable<any> {
    const url = this.baseUrl + UrlConstant.LOGIN;
    return this.post(url, param);
  }

  // Đăng xuất hệ thống
  public logout() {
    this.stopRefreshTokenTimer();
    const url = this.baseUrl + UrlConstant.LOGOUT;
    return this.post(url, null);
  }

  // Refresh token
  public refreshToken() {

  }

  //
  public isAuthenticated() {
    let accessToken = StorageService.get(STORAGE_NAME.ACCESS_TOKEN);
    if (accessToken) {
      //this.decodedAccessToken = jwt_decode(accessToken);
      //return accessToken && (this.decodedAccessToken.exp * 1000 - new Date().getTime() > 0);
      return true;
    }
  }

  // Luu thong tin sau khi login
  public saveStorage(res: any) {
    if (res && res.access_token != '') {
      let userLogin = new UserLogin();
      userLogin.id = res.userInfo.userId;
      userLogin.username = res.userInfo.userName;
      StorageService.set(STORAGE_NAME.USER_LOGIN, userLogin);
      StorageService.set(STORAGE_NAME.ACCESS_TOKEN, res.access_token);
      StorageService.set(STORAGE_NAME.REFRESH_TOKEN, res.refresh_token);
      StorageService.set(STORAGE_NAME.NUMBER_LOGIN_FAILED, 0);
      this.startRefreshTokenTimer();
    }
  }

  // start timer refresh token
  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let accessToken = StorageService.get(STORAGE_NAME.ACCESS_TOKEN);
    if (accessToken) {
      //this.decodedAccessToken = jwt_decode(accessToken);
      // set a timeout to refresh the token a minute before it expires
     /* const expires = new Date(this.decodedAccessToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (30 * 1000);*/
      //this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }
  }

  // stop timer refresh token
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
