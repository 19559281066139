import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild, ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, QueryList,
  SimpleChanges,
  TemplateRef, ViewChild, ViewChildren
} from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzTableSize } from 'ng-zorro-antd/table';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { VnptTableComponentToken, VnptTableConfig, VnptTableHeader } from './vnpt-table.interfaces';
import { ValidateService } from '../../services/common-utils.service';
import {NgTemplateNameDirective} from '../../directive/ng-template-ref-name.directive';

@Component({
  selector: 'vnpt-table',
  templateUrl: './vnpt-table.component.html',
  styleUrls: ['./vnpt-table.component.scss', '../../../core/global-style/_table.scss'],
  providers: [
    { provide: VnptTableComponentToken, useExisting: VnptTableComponent }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VnptTableComponent implements OnInit, OnChanges, AfterViewInit {
  readonly DEFAULT_CHANGE_SIZE_OPTIONS = [10,50,100]
  _dataList!: NzSafeAny[];
  _tableSize: NzTableSize = 'default';

  @Input() tableConfig!: VnptTableConfig;
  @Input() rangeTotalTemplate: TemplateRef<any>;
  @Output() onClickNode: EventEmitter<any> = new EventEmitter<any>();
  // Nếu checkbox = true
  @Input() checkedCashArrayFromComment: NzSafeAny[] = [];
  @Input() attrCheck: string = 'id';

  @Input()
  set tableData(value: NzSafeAny[]) {
    this._dataList = value;
    if (this.tableConfig.showCheckbox) {
      this._dataList.forEach((item) => {
        item['_checked'] = false;
      });
    }
  }

  get tableData(): NzSafeAny[] {
    return this._dataList;
  }

  @Output() changePageNum = new EventEmitter<number>();
  @Output() changePageSize = new EventEmitter<number>();
  @Output() selectedChange: EventEmitter<NzSafeAny[]> = new EventEmitter<NzSafeAny[]>();
  // @Output() sortFn: EventEmitter<SortFile> = new EventEmitter<SortFile>();
  @Input() @InputBoolean() showSizeChanger = false;
  @Input() @InputBoolean() showQuickJumper = false;
  indeterminate: boolean = false;
  allChecked: boolean = false;
  rowSelected: any;
  @ContentChildren(NgTemplateNameDirective) private _templates: QueryList<NgTemplateNameDirective>;

  constructor(private cdr: ChangeDetectorRef, public validateService: ValidateService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableConfig.showCheckbox) {
      this.refreshStatus();
    }
  }

  tableChangeDetection(): void {
    this._dataList = [...this._dataList??[]];
    this.cdr.markForCheck();
  }

  refreshStatus(): void {
    this._dataList.forEach((item) => {
      const index = this.checkedCashArrayFromComment.findIndex((cashItem) => {
        return item[this.attrCheck] === cashItem[this.attrCheck];
      });
      if (index !== -1) {
        item['_checked'] = true;
      } else {
        item['_checked'] = false;
      }
    });
    const allChecked = this._dataList.length > 0 && this._dataList.every((item) => {
      return item['_checked'] === true;
    });
    const allUnChecked = this._dataList.every(item => item['_checked'] !== true);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
  }

  checkFn(dataItem: NzSafeAny, isChecked: boolean): void {
    dataItem['_checked'] = isChecked;
    const index = this.checkedCashArrayFromComment.findIndex((cashItem) => cashItem[this.attrCheck] === dataItem[this.attrCheck]);
    if (isChecked) {
      if (index === -1) {
        this.checkedCashArrayFromComment.push(dataItem);
      }
    } else {
      if (index !== -1) {
        this.checkedCashArrayFromComment.splice(index, 1);
      }
    }
  }

  onAllChecked(isChecked: boolean): void {
    this._dataList.forEach((item) => {
      this.checkFn(item, isChecked);
    });
    this.selectedChange.emit(this.checkedCashArrayFromComment);
    this.refreshStatus();
  }

  public checkRowSingle(isChecked: boolean, selectIndex: number): void {
    this.checkFn(this._dataList[selectIndex], isChecked);
    this.selectedChange.emit(this.checkedCashArrayFromComment);
    this.refreshStatus();
  }

  onResize({ width }: NzResizeEvent, col: string): void {
    this.tableConfig.headers = (this.tableConfig.headers.map(e => (e.title === col ? {
      ...e,
      width: +`${width}`
    } : e))) as VnptTableHeader[];
  }

  onPageSizeChange($event: number): void {
    this.rowSelected = null;
    this.onClickNode.emit(null);
    this.changePageSize.emit($event);
  }

  onPageIndexChange($event: number): void {
    this.rowSelected = null;
    this.onClickNode.emit(null);
    this.changePageNum.emit($event);
  }

  // onQueryParamsChange(tableQueryParams: NzTableQueryParams): void {
  //   this.changePageNum.emit(tableQueryParams.pageSize);
  // }

  public trackByTableHead(index: number, item: NzSafeAny): NzSafeAny {
    return item;
  }

  public trackByTableBody(index: number, item: NzSafeAny): NzSafeAny {
    return item;
  }

  // Sort
  // changeSort(tableHeader: MBTableHeader): void {
  //   this.tableConfig.headers.forEach(item => {
  //     if (item.field !== tableHeader.field) {
  //       item.sortDir = undefined;
  //     }
  //   })
  //   const sortDicArray: [undefined, 'asc', 'desc'] = [undefined, 'asc', 'desc'];
  //   const index = sortDicArray.findIndex((item) => item === tableHeader.sortDir);
  //   tableHeader.sortDir = (index === sortDicArray.length - 1) ? sortDicArray[0] : sortDicArray[index + 1];
  //   this.sortFn.emit({fileName: tableHeader.field!, sortDir: tableHeader.sortDir})
  // }
  doClickItem(row: any) {
    if (this.tableConfig.activeRow) {
      this.rowSelected = row;
    }
    this.onClickNode.emit(row);
  }

  getData(row: any) {
    return Object.assign({}, {data: row}, row);
  }

  getTemplateRef(template: any ){
    if (template instanceof  TemplateRef) return template;
    if (typeof template === "string") return this._templates.find(item => item.name === template)?.template;
    return undefined
  }

  ngAfterViewInit(): void {
    // console.log(this._templates)
  }
}
