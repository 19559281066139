import {Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {noop} from 'rxjs';
import {InputBoolean} from "ng-zorro-antd/core/util";

export class SelectModal {
  action: 'SUBMIT' | 'CANCEL' | 'NG_MODEL_CHANGE';
  isCheckAll = false;
  listOfSelected?: any;
  itemChecked?: any;
  itemSelected?: any;

  constructor(action?: 'SUBMIT' | 'CANCEL' | 'NG_MODEL_CHANGE', listOfSelected: any = null) {
    this.action = action;
    this.listOfSelected = listOfSelected;
  }
}

@Component({
  selector: 'vnpt-select',
  templateUrl: './select.component.html',
  styleUrls: ['../../../core/global-style/_select.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() vnptDataSelects: any[] = [];
  @Input() vnptShowSearch = true;
  @Input() @InputBoolean() vnptDisable = false;
  @Input() vnptPlaceholder = '';
  @Input() vnptSelectIcon: 'search' | 'down' | string;
  @Input() vnptOptionHeightPx = 56;
  @Input() vnptOptionOverflowSize = 5;
  @Input() vnptLabelText: string;
  @Input() vnptKeyLabel: string = 'label';
  @Input() vnptKeyValue: string = 'value';
  @Input() @InputBoolean() vnptKeyValueStringType: boolean = false;
  @Input() vnptErrorDefs: {errorName: string, errorDescription: string}[];
  @Input() vnptShowIconMessage = true;
  @Input() vnptShowError = false;
  @Input() vnptErrors: any;
  @Input() vnptType: 'default' | 'warning' | 'error' | 'success' = 'default';
  @Input() vnptIsLoading = false;
  @Input() vnptShowClear = true;
  @Input() @InputBoolean() vnptRequired = false;
  @Output() vnptEventEmit: EventEmitter<SelectModal> = new EventEmitter<SelectModal>();
  listOfSelectedValue: any;

  isOpenOptions = false;
  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;
  ngControl?: NgControl;
  inputMessageClass = 'input__message';
  textMessageValue: any;
  showIcon: any;
  iconType: any;
  inputGroupGroupClass = 'select__group--group';

  classGroupGroup = {
    default: 'select__group--group--default',
    warning: 'select__group--group--warning',
    error: 'select__group--group--error',
    success: 'select__group--group--success',
  };

  classMessage = {
    default: 'select__message--default',
    warning: 'select__message--warning',
    error: 'select__message--error',
    success: 'select__message--success'
  };

  classIcon = {
    warning: 'warning',
    error: 'warning',
    success: 'check-circle'
  };

  constructor(
    private inj: Injector,
  ) {
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  ngOnChanges() {
    this.configInput();
    this.setErrorMessage();
    if (this.listOfSelectedValue) {
      const emit = new SelectModal('NG_MODEL_CHANGE',this.listOfSelectedValue);
      emit.itemSelected = this.vnptDataSelects ? this.vnptDataSelects[this.vnptDataSelects.findIndex(item => item[this.vnptKeyValue] === this.listOfSelectedValue)] : {};
      this.vnptEventEmit.emit(emit);
    }
  }

  configInput() {
    this.inputGroupGroupClass = 'select__group--group' + ' ';
    this.inputMessageClass = 'select__message ' + ' ';
    switch (this.vnptType) {
      case 'default':
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
      case 'warning':
        this.inputGroupGroupClass += this.classGroupGroup.warning;
        this.inputMessageClass += this.classMessage.warning;
        this.iconType = this.classIcon.warning;
        break;
      case 'error':
        this.inputGroupGroupClass += this.classGroupGroup.error;
        this.inputMessageClass += this.classMessage.error;
        this.iconType = this.classIcon.error;
        break;
      case 'success':
        this.inputGroupGroupClass += this.classGroupGroup.success;
        this.inputMessageClass += this.classMessage.success;
        this.iconType = this.classIcon.success;
        break;
      default:
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
    }
  }

  setErrorMessage() {
    if (this.vnptErrors) {
      for(let error of this.vnptErrorDefs) {
        let key = error.errorName;
        if (this.vnptErrors[key]) {
          this.textMessageValue = error.errorDescription;
        }
      }
    }
  }

  selectItem($event: any) {
    this.onChange($event);
    const emit = new SelectModal('NG_MODEL_CHANGE',this.listOfSelectedValue);
    emit.itemSelected = this.vnptDataSelects[this.vnptDataSelects?.findIndex(item => item[this.vnptKeyValue] === $event)];
    this.vnptEventEmit.emit(emit);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.vnptDisable = isDisabled;
  }

  writeValue(obj: any): void {
    this.listOfSelectedValue = obj;
    this.onChange(this.listOfSelectedValue);
  }
}
