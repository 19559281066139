import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'highlight'
})
export class HighlightSearch implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(value: any, args: any): any {
    if (!args) { return value; }
    const re = new RegExp(args, 'gi'); // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    const match = value.match(re);
    const replacedValue = match && match.length > 0 ? value.replace(re, "<mark>" + match[0] + "</mark>") : value;
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue)
  }
}
