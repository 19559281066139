import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CustomHttpInterceptor} from './core/intercepter/http-intercepter';
import {BrowserModule} from '@angular/platform-browser';
import {IModuleTranslationOptions, ModuleTranslateLoader} from '@larscom/ngx-translate-module-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './shared/shared.module';
import {RouterModule} from '@angular/router';
import {ToastrModule} from 'ngx-toastr';
import {NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {vi} from 'date-fns/locale';
import {NZ_DATE_LOCALE} from 'ng-zorro-antd/i18n';
import {LoginComponent} from './pages/login/login.component';
import {NgModule} from '@angular/core';
import { AppComponent } from './app.component';

export function ModuleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';
  const options: IModuleTranslationOptions = {
    modules: [
      {baseTranslateUrl}
    ]
  };
  return new ModuleTranslateLoader(http, options);
}


@NgModule({
  imports: [
    AppRoutingModule,
    SharedModule,
    RouterModule,
    BrowserModule,
    PagesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: ModuleHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({})
  ],

  providers: [
    {provide: NZ_DATE_LOCALE, useValue: vi},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: NZ_CONFIG,
      useValue: {
        collapse: {
          nzGhost: true
        },
        collapsePanel: {
          nzShowArrow: false
        }
      }
    }
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent, LoginComponent]
})
export class AppModule {
  constructor() {
  }
}
