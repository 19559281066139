export class BaseResponse<T, F> {
  requestId?: string;
  requestDate?: any;
  messageCode?: string;
  message?: string;

  data?: T;
  optional?: F

  static getMessage = (res: any) => {
    const response: BaseResponse<any, any> = Object.assign(res, new BaseResponse());
    if (!response?.message || !Array.isArray(response.message)) return "Hệ thống đang bận. Vui lòng thử lại sau";
    else return response.message ? response.message.join("</br>") : "Hệ thống đang bận. Vui lòng thử lại";
  }
}


export const BASE_RESPONSE_STATUS = {
  "OK": "SUCCESS",
  "NOT_FOUND": "NOT_FOUND"
}
