import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {IdleUserCheckService} from './core/services/idle-user-check.service';
import {StorageService} from './core/services/storage.service';
import {AuthService} from './pages/login/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translate: TranslateService,
              private idleUserCheckService: IdleUserCheckService,
              private authService: AuthService,
              private router: Router) {
    translate.addLangs(['vn', 'en']);
    translate.use('vn');

  }

  ngOnInit(): void {
    /**
     * Bắt đầu tính thời gian
     */
    this.initTimer();
  }

  initTimer() {
    // thời gian rảnh: 60 phút
    this.idleUserCheckService.USER_IDLE_TIMER_VALUE_IN_MIN = 60;
    this.idleUserCheckService.initilizeSessionTimeout();
    this.idleUserCheckService.userIdlenessChecker.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });
  }
  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;
      case 'RESET_TIMER':
        break;
      case 'STOPPED_TIMER':
        IdleUserCheckService.runTimer = false;
        this.authService.logout();
        StorageService.clear();
        this.router.navigate(['/login']);
        break;

      default:
        break;
    }
  }
}
