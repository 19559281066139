export const SYSTEM_FORMAT_DATA = {
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  MONTH_TIME_FORMAT: 'MM/YYYY',
  DATE_FORMAT_MILLISECONDS: 'YYYYMMDDHHmmssSSS',
  EMAIL_FORMAT: '[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+',
  PHONE_FORMAT: '^[ ]*((\\84)|0)([0-9]{9,10})[ ]*',
  FLOAT_FORMAT: '[^.]*([.](.{1,2}))?',
  NUMBER_FORMAT: '[^.]+',
};

export const STORAGE_NAME = {
  USER_LOGIN: 'USER_LOGIN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  NUMBER_LOGIN_FAILED: 'NUMBER_LOGIN_FAILED'
}
