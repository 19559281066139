import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { CompatibleDate, DisabledDateFn, NzDateMode, SupportTimeOptions } from 'ng-zorro-antd/date-picker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { FunctionProp, NzSafeAny } from 'ng-zorro-antd/core/types';
import { noop } from 'rxjs';
import { toBoolean } from 'ng-zorro-antd/core/util';
import { CommonUtils } from '../../services/common-utils.service';
import * as moment from 'moment';
import { SYSTEM_FORMAT_DATA } from 'src/app/core/constant/system.constants';

@Component({
  selector: 'vnpt-date-time-work',
  templateUrl: './vnpt-date-time-work.component.html',
  styleUrls: ['../../../core/global-style/_date-picker.scss', './vnpt-date-time-work.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VnptDateTimeWorkComponent),
    }
  ]
})
export class VnptDateTimeWorkComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() vnptLabelText: string;
  @Input() vnptAutofocus: boolean = false;
  @Input() vnptDatePickerIcon: 'search' | 'down' | string;
  @Input() vnptDisabled: boolean = false;
  @Input() vnptInputReadOnly: boolean = false;
  @Input() vnptInline: boolean = false;
  @Input() vnptMode: NzDateMode = 'date';
  @Input() vnptPlaceholderText: string = '';
  @Input() vnptPlaceholderTime: string = '__ : __';
  @Input() vnptSuffixIcon: string | TemplateRef<NzSafeAny> = 'calendar';
  @Input() vnptDisabledDate?: (d: Date) => boolean;
  @Input() vnptShowToday: boolean;
  @Input() vnptDisabledTime?: DisabledDateFn;
  @Input() vnptDateRender?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<Date> | string>;
  @Input() vnptRenderExtraFooter?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<NzSafeAny> | string>;
  @Input() required: boolean;
  @Input() vnptIsStartTime: boolean = true;

  @Output() readonly vnptOnOpenChange = new EventEmitter<boolean>();
  @Output() readonly vnptOnPanelChange = new EventEmitter<NzDateMode | NzDateMode[] | string | string[]>();
  @Output() readonly vnptOnCalendarChange = new EventEmitter<Array<Date | null>>();
  @Output() readonly vnptOnOk = new EventEmitter<CompatibleDate | null>();

  @Input() vnptErrorDefs: { errorName: string, errorDescription: string }[];
  @Input() vnptShowIconMessage = true;
  @Input() vnptShowError = false;
  @Input() vnptErrors: any;
  @Input() vnptType: 'default' | 'warning' | 'error' | 'success' = 'default';
  selectedTime = null;
  selectedDate = null;

  value: any;
  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;
  ngControl?: NgControl;
  inputMessageClass = 'input__message';
  textMessageValue: any;
  showIcon: any;
  iconType: any;
  inputGroupGroupClass = 'datepicker__group--group';

  classGroupGroup = {
    default: 'datepicker__group--group--default',
    warning: 'datepicker__group--group--warning',
    error: 'datepicker__group--group--error',
    success: 'datepicker__group--group--success',
  };

  classMessage = {
    default: 'datepicker__message--default',
    warning: 'datepicker__message--warning',
    error: 'datepicker__message--error',
    success: 'datepicker__message--success'
  };

  classIcon = {
    warning: 'warning',
    error: 'close-circle',
    success: 'check-circle'
  };

  timeStart = [
    { value: '08:00', label: '08:00' },
    { value: '13:30', label: '13:30' },
  ];

  timeEnd = [
    { value: '12:00', label: '12:00' },
    { value: '17:30', label: '17:30' },
  ];

  constructor(private inj: Injector) { }

  ngOnChanges() {
    this.configInput();
    this.setErrorMessage();
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  writeValue(obj: any) {
    this.selectedTime = obj ? moment(obj, SYSTEM_FORMAT_DATA.DATE_TIME_FORMAT).format('HH:mm') : '';
    this.value = obj ? moment(obj, SYSTEM_FORMAT_DATA.DATE_TIME_FORMAT).toDate() : null;
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.vnptDisabled = isDisabled;
  }

  openChange($event) {
    this.vnptOnOpenChange.emit($event)
  }

  onOk($event) {
    this.vnptOnOk.emit($event)
  }

  onInputChange($event: any) {
    this.selectedDate = $event;
    if (!this.selectedTime) {
      const datasource = this.vnptIsStartTime ? this.timeStart : this.timeEnd;
      this.selectedTime = datasource[0].value;
    }
    this._handleOnChange($event, this.selectedTime);
  }

  configInput() {
    this.inputGroupGroupClass = 'datepicker__group--group' + ' ';
    this.inputMessageClass = 'datepicker__message ' + ' ';
    switch (this.vnptType) {
      case 'default':
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
      case 'warning':
        this.inputGroupGroupClass += this.classGroupGroup.warning;
        this.inputMessageClass += this.classMessage.warning;
        this.iconType = this.classIcon.warning;
        break;
      case 'error':
        this.inputGroupGroupClass += this.classGroupGroup.error;
        this.inputMessageClass += this.classMessage.error;
        this.iconType = this.classIcon.error;
        break;
      case 'success':
        this.inputGroupGroupClass += this.classGroupGroup.success;
        this.inputMessageClass += this.classMessage.success;
        this.iconType = this.classIcon.success;
        break;
      default:
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
    }
  }

  setErrorMessage() {
    if (this.vnptErrors && this.vnptErrorDefs) {
      for (let error of this.vnptErrorDefs) {
        let key = error.errorName;
        if (this.vnptErrors[key]) {
          this.textMessageValue = error.errorDescription;
        }
      }
    }
  }

  /**
   * changeTime
   * @param evt
   */
  changeTime(evt) {
    this.selectedTime = evt.listOfSelected;
    this._handleOnChange(this.value, this.selectedTime);
  }

  /**
   * _getDetailTime
   * @param strTime
   * @returns
   */
  _getDetailTime(strTime) {
    if (CommonUtils.isNullOrEmpty(strTime)) {
      return {};
    }
    const arr = strTime.split(':');
    return {
      hour: parseInt(arr[0]),
      minute: parseInt(arr[1]),
    }
  }

  /**
   * _handleOnChange
   * @param date
   * @param time
   */
  _handleOnChange(date, time) {
    if(!date || !time){
      !date && (this.selectedDate = null);
      !time && (this.selectedTime = null);
      this.onChange(null);
      return;
    }
    const { hour, minute } = this._getDetailTime(time);
    const momentDate = moment(date).set({ hour: hour, minute: minute, second: 0 }).toDate();
    this.selectedDate = momentDate;
    this.onChange(moment(momentDate).format(SYSTEM_FORMAT_DATA.DATE_TIME_FORMAT));
  }
}
