import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { UrlConstant } from 'src/app/shared/constant/url.class';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService extends BaseService {
  readonly baseUrl = UrlConstant.API_VERSION;

  getMenus() {
    return this.get(this.baseUrl + UrlConstant.CURRENT_USER.PREFIX + UrlConstant.CURRENT_USER.MENUS);
  }

  canAccessMenu(f: string) {
    return this.post(this.baseUrl + UrlConstant.CURRENT_USER.PREFIX + UrlConstant.CURRENT_USER.CAN_ACCESS_MENU, {"data": f});
  }
}
