import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import {StorageService} from "../services/storage.service";
import {STORAGE_NAME} from "../constant/system.constants";
import {AuthService} from "../../pages/login/service/auth.service";
import {ToastrService} from 'ngx-toastr';
const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private authService: AuthService,
    private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();
    let accessToken = StorageService.get(STORAGE_NAME.ACCESS_TOKEN);
    if (accessToken && !url.includes('login') && !url.includes('refresh-token')) {
      req = this.addTokenHeader(req, accessToken);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
        }
      }, error => {
        if (403 == error.status) {
          this.toastrService.error("Bạn không có quyền truy cập api");
        }
        if (401 == error.status) {
          this.authService.logout();
          StorageService.clear();
          this.router.navigate(['/login']);
        }
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
    /* for Spring Boot back-end */
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + accessToken) });
  }
}
