export class UrlConstant {
  public static readonly API_VERSION = '/api';

  public static readonly ROLE = {
    PREFIX:'/roles',
    LIST_ALL: '/list-all',
    LIST_PAGE: '/list',
    CREATE: '/create',
    UPDATE: '/update',
    DELETE_BY_IDS: '/delete',
    DELETE_BY_ID: '/delete-by-id',
    SEARCH_ALL: '/search-all',
    SEARCH: '/search',
    CHANGE_ENABLE: '/change-enable/{role}'
  };

  public static readonly PERMISSION = {
    PREFIX:'/permissions',
    LIST_ALL: '/list-all',
    LIST_PAGE: '/list',
    CREATE: '/create',
    UPDATE: '/update',
    DELETE_BY_IDS: '/delete',
    DELETE_BY_ID: '/delete-by-id',
    SEARCH_ALL: '/search-all',
    SEARCH: '/search',
    CHANGE_ENABLE: '/change-enable/{permissionId}'
  };

  public static readonly MENU = {
    PREFIX:'/menus',
    FUNCTION_LIST: '/functions-list',
    LIST: '/list',
    LIST_BY_TREE: '/list-by-tree',
    LIST_BY_CURRENT_USER: '/list-menus-by-current-user',
    CREATE: '/add',
    UPDATE: '/update',
    DELETE_BY_ID: '/delete/{menu_id}',
    SEARCH_BY_NAME: '/search-by-name',
    GET_ROLES: '/{menuId}/roles-list',
    UNASSIGN_ROLE: '/{menuId}/un-assigned-role',
    SEARCH_BY_PARENT_ID: '/search-by-parent-id',
  };

  /**
   * APP
   */
  public static readonly CURRENT_USER = {
    PREFIX: '/current-user',
    MENUS: '/menus',
    CAN_ACCESS_MENU: '/can-access-menu',
  }

}
