import {Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, TemplateRef} from '@angular/core';
import {CompatibleDate, DisabledTimeFn, NzDateMode, SupportTimeOptions} from 'ng-zorro-antd/date-picker';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {FunctionProp, NzSafeAny} from 'ng-zorro-antd/core/types';
import {noop} from 'rxjs';
import {InputBoolean, toBoolean} from 'ng-zorro-antd/core/util';

@Component({
  selector: 'vnpt-date-picker',
  templateUrl: './vnpt-date-picker.component.html',
  styleUrls: ['../../../core/global-style/_date-picker.scss', 'vnpt-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VnptDatePickerComponent),
    }
  ]
})
export class VnptDatePickerComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() vnptLabelText: string;
  @Input() vnptAutofocus: boolean = false;
  @Input() vnptDatePickerIcon: 'search' | 'down' | string;
  @Input() vnptDisabled: boolean = false;
  @Input() vnptInputReadOnly: boolean = false;
  @Input() vnptInline: boolean = false;
  @Input() vnptMode: NzDateMode = 'date';
  @Input() vnptPlaceholderText: string = '';
  @Input() vnptSuffixIcon: string | TemplateRef<NzSafeAny> = 'calendar';
  @Input() vnptDisabledDate?: (d: Date) => boolean;
  @Input() vnptShowToday: boolean;
  @Input() vnptDisabledTime?: DisabledTimeFn;
  @Input() vnptDateRender?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<Date> | string>;
  @Input() vnptRenderExtraFooter?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<NzSafeAny> | string>;
  @Input() required: boolean;
  private showTime: SupportTimeOptions | boolean = false;
  @Input() @InputBoolean() vnptQuarterMode = false;

  @Output() readonly vnptOnOpenChange = new EventEmitter<boolean>();
  @Output() readonly vnptOnPanelChange = new EventEmitter<NzDateMode | NzDateMode[] | string | string[]>();
  @Output() readonly vnptOnCalendarChange = new EventEmitter<Array<Date | null>>();
  @Output() readonly vnptOnOk = new EventEmitter<CompatibleDate | null>();

  @Input() vnptErrorDefs: { errorName: string, errorDescription: string }[];
  @Input() vnptShowIconMessage = true;
  @Input() vnptShowError = false;
  @Input() vnptErrors: any;
  @Input() vnptType: 'default' | 'warning' | 'error' | 'success' = 'default';

  @Input() get mbShowTime(): SupportTimeOptions | boolean {
    return this.showTime;
  }

  set mbShowTime(value: SupportTimeOptions | boolean) {
    this.showTime = typeof value === 'object' ? value : toBoolean(value);
  }

  value: any;
  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;
  ngControl?: NgControl;
  inputMessageClass = 'input__message';
  textMessageValue: any;
  showIcon: any;
  iconType: any;
  inputGroupGroupClass = 'datepicker__group--group';

  classGroupGroup = {
    default: 'datepicker__group--group--default',
    warning: 'datepicker__group--group--warning',
    error: 'datepicker__group--group--error',
    success: 'datepicker__group--group--success',
  };

  classMessage = {
    default: 'datepicker__message--default',
    warning: 'datepicker__message--warning',
    error: 'datepicker__message--error',
    success: 'datepicker__message--success'
  };

  classIcon = {
    warning: 'warning',
    error: 'close-circle',
    success: 'check-circle'
  };

  alive: boolean = true;
  year: number;
  yearDefault = new Date().getFullYear()
  quarterDefault = 'Q' + (1 + Math.floor(new Date().getMonth() / 3))
  quarter: string;
  showQuarter: boolean = true;
  year10: number;
  options: any[] = [
    {value: 'Q1', label: ['Quý 1']},
    {value: 'Q2', label: ['Quý 2']},
    {value: 'Q3', label: ['Quý 3']},
    {value: 'Q4', label: ['Quý 4']}
  ];
  visible = false;

  constructor(private inj: Injector) {
  }

  ngOnChanges() {
    this.configInput();
    this.setErrorMessage();
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  changeYear(year) {
    this.year = year || this.yearDefault;
    this.quarter = this.quarter || this.quarterDefault
    this.value = this.quarter + '-' + this.year;
    this.onChange(this.value);
  }

  changeShowQuarter() {
    this.showQuarter = !this.showQuarter
    if (!this.showQuarter)
      this.year10 = this.year ? 10 * Math.floor(this.year / 10) : 10 * Math.floor(this.yearDefault / 10)
  }

  click(quarter) {
    this.quarter = quarter;
    this.year = this.year || this.yearDefault
    this.value = this.quarter + '-' + this.year;
    this.visible = false;
    this.onChange(this.value);
  }

  writeValue(obj: any) {
    this.value = obj;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.vnptDisabled = isDisabled;
  }

  openChange($event) {
    this.vnptOnOpenChange.emit($event)
  }

  onOk($event) {
    this.vnptOnOk.emit($event)
  }

  onInputChange($event: any) {
      this.onChange($event);
  }

  configInput() {
    this.inputGroupGroupClass = 'datepicker__group--group' + ' ';
    this.inputMessageClass = 'datepicker__message ' + ' ';
    switch (this.vnptType) {
      case 'default':
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
      case 'warning':
        this.inputGroupGroupClass += this.classGroupGroup.warning;
        this.inputMessageClass += this.classMessage.warning;
        this.iconType = this.classIcon.warning;
        break;
      case 'error':
        this.inputGroupGroupClass += this.classGroupGroup.error;
        this.inputMessageClass += this.classMessage.error;
        this.iconType = this.classIcon.error;
        break;
      case 'success':
        this.inputGroupGroupClass += this.classGroupGroup.success;
        this.inputMessageClass += this.classMessage.success;
        this.iconType = this.classIcon.success;
        break;
      default:
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
    }
  }

  setErrorMessage() {
    if (this.vnptErrors) {
      for (let error of this.vnptErrorDefs) {
        let key = error.errorName;
        if (this.vnptErrors[key]) {
          this.textMessageValue = error.errorDescription;
        }
      }
    }
  }
}
