import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { noop } from 'rxjs';
import { InputBoolean } from 'ng-zorro-antd/core/util';

export class SelectCheckAbleModal {
  action: 'SUBMIT' | 'CANCEL' | 'NG_MODEL_CHANGE';
  isCheckAll = false;
  listOfSelected?: any[] = [];
  itemChecked?: any;
  listItemSelected?: any[] = [];

  constructor(action?: 'SUBMIT' | 'CANCEL' | 'NG_MODEL_CHANGE', isCheckAll: boolean = false, listOfSelected: any[] = [], itemChecked?: any) {
    this.action = action;
    this.isCheckAll = isCheckAll;
    this.listOfSelected = listOfSelected;
    this.itemChecked = itemChecked;
  }
}

@Component({
  selector: 'vnpt-select-check-able',
  templateUrl: './select-able.component.html',
  styleUrls: ['../../../core/global-style/_select.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectAbleComponent),
    }
  ]
})
export class SelectAbleComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() vnptDataSelects: any[] = [];
  @Input() vnptShowCheckAll = true;
  @Input() vnptShowAction = true;
  @Input() vnptShowSearch = true;
  @Input() vnptCheckAllKey = 'ALL';
  @Input() vnptPlaceholder = '';
  @Input() vnptSelectIcon: 'search' | 'down' | string;
  @Input() vnptMaxTagCount = 3;
  @Input() vnptOptionHeightPx = 56;
  @Input() vnptOptionOverflowSize = 5;
  @Input() vnptLabelText: string;
  @Input() vnptKeyLabel: string = 'label';
  @Input() vnptKeyValue: string = 'value';
  @Input() vnptKeyStyle: string = 'style';
  @Input() @InputBoolean() vnptKeyValueStringType: boolean = false;
  @Input() vnptErrorDefs: {errorName: string, errorDescription: string}[];
  @Input() vnptShowIconMessage = true;
  @Input() vnptShowError = false;
  @Input() vnptErrors: any;
  @Input() vnptType: 'default' | 'warning' | 'error' | 'success' = 'default';
  @Input() vnptIsLoading = false;
  @Input() vnptIsOpenOptions = false;
  @Input() vnptTranslate = false;
  @Input() @InputBoolean() vnptRequired = false;
  @Output() vnptEventEmit: EventEmitter<SelectCheckAbleModal> = new EventEmitter<SelectCheckAbleModal>();
  listOfSelectedValue: any[] = [];

  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;
  ngControl?: NgControl;
  inputMessageClass = 'input__message';
  textMessageValue: any;
  showIcon: any;
  iconType: any;
  inputGroupGroupClass = 'select__group--group';

  classGroupGroup = {
    default: 'select__group--group--default',
    warning: 'select__group--group--warning',
    error: 'select__group--group--error',
    success: 'select__group--group--success',
  };

  classMessage = {
    default: 'select__message--default',
    warning: 'select__message--warning',
    error: 'select__message--error',
    success: 'select__message--success'
  };

  classIcon = {
    warning: 'warning',
    error: 'warning',
    success: 'check-circle'
  };

  constructor(
    private inj: Injector,
  ) {
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  ngOnChanges() {
    this.configInput();
    this.setErrorMessage();
    if (this.listOfSelectedValue) {
      const emit = new SelectCheckAbleModal('SUBMIT', this.listOfSelectedValue?.length === this.vnptDataSelects.length, this.listOfSelectedValue);
      this.listOfSelectedValue.forEach(item => {
        let it = this.vnptDataSelects ? this.vnptDataSelects[this.vnptDataSelects.findIndex(i => i[this.vnptKeyValue] === item)] : {};
        emit.listItemSelected.push(it);
      });
      this.vnptEventEmit.emit(emit);
    }
  }

  configInput() {
    this.inputGroupGroupClass = 'select__group--group' + ' ';
    this.inputMessageClass = 'select__message ' + ' ';
    switch (this.vnptType) {
      case 'default':
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
      case 'warning':
        this.inputGroupGroupClass += this.classGroupGroup.warning;
        this.inputMessageClass += this.classMessage.warning;
        this.iconType = this.classIcon.warning;
        break;
      case 'error':
        this.inputGroupGroupClass += this.classGroupGroup.error;
        this.inputMessageClass += this.classMessage.error;
        this.iconType = this.classIcon.error;
        break;
      case 'success':
        this.inputGroupGroupClass += this.classGroupGroup.success;
        this.inputMessageClass += this.classMessage.success;
        this.iconType = this.classIcon.success;
        break;
      default:
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputMessageClass += this.classMessage.default;
        break;
    }
  }

  setErrorMessage() {
    if (this.vnptErrors) {
      for(let error of this.vnptErrorDefs) {
        let key = error.errorName;
        if (this.vnptErrors[key]) {
          this.textMessageValue = error.errorDescription;
        }
      }
    }
  }

  checkAll($event: boolean) {
    if ($event) {
      this.listOfSelectedValue = this.vnptDataSelects?.filter(item => item.disable !== true).map((item) => {
         return item[this.vnptKeyValue];
      });
    } else {
      this.listOfSelectedValue = [];
    }
    const emit = new SelectCheckAbleModal('NG_MODEL_CHANGE', $event, this.listOfSelectedValue, this.vnptCheckAllKey);
    this.vnptEventEmit.emit(emit);
    this.onChange(this.listOfSelectedValue);
  }

  cancel() {
    this.vnptIsOpenOptions = false;
    this.listOfSelectedValue = [];
    const emit = new SelectCheckAbleModal('CANCEL');
    this.vnptEventEmit.emit(emit);
    this.onChange(this.listOfSelectedValue);
  }

  submit() {
    this.vnptIsOpenOptions = false;
    const emit = new SelectCheckAbleModal('SUBMIT', this.listOfSelectedValue?.length === this.vnptDataSelects.length, this.listOfSelectedValue);
    this.listOfSelectedValue.forEach(item => {
      let it = this.vnptDataSelects ? this.vnptDataSelects[this.vnptDataSelects.findIndex(i => i[this.vnptKeyValue] === item)] : {};
      emit.listItemSelected.push(it);
    });
    this.vnptEventEmit.emit(emit);
    this.onChange(this.listOfSelectedValue);
  }

  checkItem($event: boolean, value: any) {
    let dataSelected = Object.assign([], this.listOfSelectedValue);
    if ($event) {
      dataSelected.push(value);
    } else {
      dataSelected = dataSelected.filter(item => item !== value);
    }
    this.listOfSelectedValue = dataSelected;
    const emit = new SelectCheckAbleModal('NG_MODEL_CHANGE', this.listOfSelectedValue?.length === this.vnptDataSelects.length, this.listOfSelectedValue, value + '');
    this.vnptEventEmit.emit(emit);
  }

  selectItem($event: any) {
    this.onChange($event);
    const emit = new SelectCheckAbleModal('NG_MODEL_CHANGE', this.listOfSelectedValue?.length === this.vnptDataSelects.length, this.listOfSelectedValue, 'UNDEFINED');
    this.listOfSelectedValue.forEach(item => {
      let it = this.vnptDataSelects ? this.vnptDataSelects[this.vnptDataSelects.findIndex(i => i[this.vnptKeyValue] === item)] : {};
      emit.listItemSelected.push(it);
    });
    this.vnptEventEmit.emit(emit);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.listOfSelectedValue = obj;
    this.onChange(this.listOfSelectedValue);
  }
}
