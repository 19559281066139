import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {HeaderEventEmit} from './header/header-event-emit';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../services/session.service';
import {NzModalService} from "ng-zorro-antd/modal";
import {CurrentUserService} from "../../shared/services/current-user.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MapPipe} from "../../shared/pipes/map.pipe";
import {SecurePipe} from "../../shared/pipes/secure.pipe";

export interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [MapPipe, SecurePipe]
})


export class LayoutComponent implements OnInit, AfterViewInit, AfterViewChecked {
  static readonly ROUTE_DATA_PAGENAME = 'pageName';
  isCollapsed = false;
  pageName: string;
  menus: any = [];
  menusOrigin: any = [];
  isMobile: any;
  openMenuMap: { [id: string]: boolean } = {};
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth = 993;
  currentUrl: string;
  breadcrumbs: IBreadCrumb[];
  selectedMenuId: string;
  _routerState: string;
  deviceMobileNotByWidth = false;
  firstClick = true;

  @ViewChild('elementUlMenu', { static: false }) elementUlMenu: ElementRef;
  @ViewChild('navElement', { static: false }) navElement: ElementRef;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private session: SessionService,
    private nzModalService: NzModalService,
    private currentUserService: CurrentUserService,
    private cdRef: ChangeDetectorRef) {
    this.getCurrentUrl(this.route);
    this.checkDevice();
    if (window.innerWidth < this.mobileWidth) {
      this.isMobile = true;
    }
    this.breadcrumbs = this.buildBreadCrumb(this.activeRoute.root);
  }

  ngOnInit(): void {
    this.route.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getPageInfo())
      )
      .subscribe((pageName: string) => {
        this.pageName = pageName;
        this.breadcrumbs = this.buildBreadCrumb(this.activeRoute.root);
      });
    this.pageName = this.getPageInfo();
    this.getMenu();
  }

  ngAfterViewInit(): void {

  }


  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getMenu() {
    this.currentUserService.getMenus().subscribe(res => {
      this.menusOrigin = res.data;
      this.menus = res;
      this.setOpenMenu(this.menusOrigin);
    });
  }

  getPageInfo() {
    let child = this.activeRoute;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data[LayoutComponent.ROUTE_DATA_PAGENAME]) {
      return child.snapshot.data[LayoutComponent.ROUTE_DATA_PAGENAME];
    }
    return '';
  }

  headerEventEmit($event: HeaderEventEmit) {
    switch ($event.subject) {
      case 'TRIGGER':
        if (this.isMobile) {
          this.firstClick = false;
          this.navElement.nativeElement.style.display = 'initial';
        }
        break;
      case 'CHAT':
        break;
      case 'SEARCH':
        break;
      default:
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
    if (!this.isMobile) {
      this.navElement.nativeElement.style.display = 'initial';
    }
    this.checkDevice();
  }

  checkDevice() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      this.deviceMobileNotByWidth = true;
      this.firstClick = true;
    } else {
      this.deviceMobileNotByWidth = false;
      this.firstClick = false;
    }
  }

  getCurrentUrl(router: Router) {
    this._routerState = this.activeRoute.snapshot['_routerState']['url'];
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
        this.nzModalService.closeAll();
      }
    });
  }

  closeMenuMobile() {
    this.firstClick = true;
    this.navElement.nativeElement.style.display = 'none';
  }

  setOpenMenu(menus: any, id?: any) {
    const getNodes = (result, object) => {
      object.open = false;
      this.openMenuMap[object.id] = false;
      if (id) {
        if (object.id === id) {
          let objectResult = Object.assign({}, object);
          result.push(objectResult);
          objectResult.open = true;
          this.openMenuMap[object.id] = true;
          return result;
        }
      } else {
        if (this.currentUrl != '/' && this.selectedMenuId != '/' && object.uri === this.currentUrl) {
          let objectResult = Object.assign({}, object);
          result.push(objectResult);
          objectResult.open = true;
          this.openMenuMap[object.id] = true;
          return result;
        }
      }
      if (Array.isArray(object.children)) {
        const subs = object.children.reduce(getNodes, []);
        if (subs.length) {
          object.open = false;
          this.openMenuMap[object.id] = false;
          for (let i = 0; i < subs.length; i++) {
            if (subs[i].open) {
              object.open = true;
              this.openMenuMap[object.id] = true;
              break;
            }
          }
          result.push({...object, subs});
        } else {
          object.open = false;
          this.openMenuMap[object.id] = false;
          result.push({...object});
        }
      } else {
        object.open = false;
        this.openMenuMap[object.id] = false;
        result.push({...object});
      }
      return result;
    };
    this.menus = menus.reduce(getNodes, []);
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadCrumb = {
      label,
      url: nextUrl
    };
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  searchMenu($event: string) {
    this.filter($event, this.menusOrigin);
  }

  filter(text, array: any) {
    const getNodes = (result, object) => {
      if (!text) {
        this.openMenuMap[object.id] = false;
        result.push(object);
        return result;
      } else {
        let index = object.displayName.toLowerCase().indexOf(text.toLowerCase());
        if (index != -1) {
          let objectResult = Object.assign({}, object);
          objectResult.displayName = objectResult.displayName.substring(0, index) + '<span class=\'text__search--highlight\'>'
            + objectResult.displayName.substring(index, index + text.length) + '</span>' + objectResult.displayName.substring(index + text.length);
          result.push(objectResult);
          this.openMenuMap[object.id] = true;
          //this.setHighlight(object.subs, text);
          return result;
        }
        if (Array.isArray(object.children)) {
          const subs = object.children.reduce(getNodes, []);
          if (subs.length) {
            result.push({ ...object, subs });
            this.openMenuMap[object.id] = true;
          } else {
            this.openMenuMap[object.id] = false;
          }
        } else {
          this.openMenuMap[object.id] = false;
        }
        return result;
      }
    };
    this.menus = array.reduce(getNodes, []);
  }

  eventHandler(elementDivSub: HTMLDivElement) {
    let heightParent = '';
    let itemActives = this.elementUlMenu.nativeElement.querySelectorAll('.item__active--true');
    let menuLi = elementDivSub.parentElement;
    let menuUl = menuLi.querySelector('.menu__ul') as HTMLElement;
    if (menuUl.style.maxHeight) {
      menuUl.style.maxHeight = null;
    } else {
      menuUl.style.maxHeight = menuUl.scrollHeight + 'px';
    }
    heightParent = menuLi.parentElement ? menuLi.parentElement.style.maxHeight : '';
    if (!menuLi.classList.contains('item__active--true')) {
      itemActives.forEach(itemActive => {
        itemActive.classList.remove('item__active--true');
        if (itemActive.classList.contains('item__li--no-flex')) {
          (itemActive.querySelector('.menu__ul') as HTMLElement).style.maxHeight = null;
        }
      });
      menuLi.classList.add('item__active--true');
    } else {
      itemActives.forEach(itemActive => {
        itemActive.classList.remove('item__active--true');
        if (itemActive.classList.contains('item__li--no-flex')) {
          (itemActive.querySelector('.menu__ul') as HTMLElement).style.maxHeight = null;
        }
      });
    }
    this.setMaxHeightParent(menuLi.parentElement, heightParent, menuUl.scrollHeight);
  }

  setMaxHeightParent(menuUl: HTMLElement, heightParent: string, itemHeight: number) {
    if (menuUl && !menuUl.classList.contains('menu__ul--level-1')) {
      menuUl.parentElement.classList.add('item__active--true');
      if (heightParent != '') {
        menuUl.style.maxHeight = +(heightParent.replace('px', '')) + itemHeight + 'px';
      }
      let menuLi = menuUl.parentElement;
      this.setMaxHeightParent(menuLi.parentElement, heightParent != '' ?
        +(heightParent.replace('px', '')) + itemHeight + 'px' : '', menuUl.scrollHeight);
    }
  }

  eventHandlerNoSub(elementLiNoSub: HTMLDivElement) {
    let menuLi = elementLiNoSub.parentElement;
    let itemActives = this.elementUlMenu.nativeElement.querySelectorAll('.item__active--true');
    itemActives.forEach(itemActive => {
      if (menuLi.classList.contains('level__1')) {
        itemActive.classList.remove('item__active--true');
        if (itemActive.classList.contains('item__li--no-flex')) {
          (itemActive.querySelector('.menu__ul') as HTMLElement).style.maxHeight = null;
        }
      } else {
        if (itemActive.classList.contains('item__li--flex')) {
          itemActive.classList.remove('item__active--true');
        }
      }
    });
    menuLi.classList.toggle('item__active--true');
  }

  getActiveMenu(id: string) {
    return this.openMenuMap[id] == true ? 'item__active--true' : '';
  }

  // transformMenuIcon(menu): Observable<SafeUrl> {
  //   return this.http
  //     .get(environment.backend.baseUrl + menu.icon, { responseType: 'blob' })
  //     .pipe(map((val) => {
  //       return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))
  //     }));
  // }
}
