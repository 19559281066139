import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {HeaderEventEmit} from './header-event-emit';
import {TranslateService} from '@ngx-translate/core';
import {NzI18nService} from 'ng-zorro-antd/i18n';
import {enUS, vi} from 'date-fns/locale';
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import { UserLogin } from '../../../shared/model/user-login';
import { StorageService } from '../../services/storage.service';
import { STORAGE_NAME } from '../../constant/system.constants';
import { en_US_ext } from '../../constant/nz-i18n/en_US.extra';
import { vi_VN_ext } from '../../constant/nz-i18n/vi_VN.extra';
import { AuthService } from 'src/app/pages/login/service/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isCollapsed: any;
  @Input() isMobile: any;
  @Output() headerEventEmit: EventEmitter<HeaderEventEmit> = new EventEmitter<HeaderEventEmit>();
  searchValue = null;
  showSearch = false;
  userLogin: UserLogin = new UserLogin();
  langCurrent: string = 'vn';

  constructor(@Inject(LOCALE_ID) protected localeId: string,
              private router: Router,
              private authService: AuthService,
              private toastrService: ToastrService,
              private i18n: NzI18nService,
              private localService: BsLocaleService,
              public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.userLogin = StorageService.get(STORAGE_NAME.USER_LOGIN);
  }

  clickEvent() {
    this.headerEventEmit.emit(new HeaderEventEmit('TRIGGER', 'CLICK'));
  }

  showInputSearch(event: 'OPEN' | 'CLOSE') {
    if (event === 'OPEN') {
      this.showSearch = true;
    } else {
      this.showSearch = false;
      this.searchValue = null;
    }
  }

  changeLanguage() {
    if (this.langCurrent === 'vn') {
      this.i18n.setLocale(en_US_ext);
      this.i18n.setDateLocale(enUS);
      this.translate.use('en');
      this.langCurrent = 'en';
      this.localService.use('en');
    } else {
      this.i18n.setLocale(vi_VN_ext);
      this.i18n.setDateLocale(vi);
      this.translate.use('vn');
      this.langCurrent = 'vn';
      this.localService.use('vn');
    }
  }

  logout() {
    this.router.navigate(['/login']);
    this.authService.logout().subscribe(res => {
      StorageService.clear();
      localStorage.clear();
      this.router.navigate(['/login']);
    }, error =>  {

    });
  }
}
