import { NgModule } from '@angular/core';
import { NgGenericPipe } from './ng-generic-pipe.pipe';

export const GENERIC_PIPES = [NgGenericPipe];

@NgModule({
  declarations: GENERIC_PIPES,
  imports: [],
  exports: GENERIC_PIPES,
})
export class NgGenericPipeModule {}

export { NgGenericPipe } from './ng-generic-pipe.pipe';
