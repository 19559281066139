import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {noop} from 'rxjs';
import {InputBoolean} from 'ng-zorro-antd/core/util';
import {NzAutocompleteComponent} from "ng-zorro-antd/auto-complete";

export class ModelInput {
  groupType?: 'noIcon' | 'icon' | 'textarea' = 'noIcon';
  type?: 'default' | 'warning' | 'error' | 'success' = 'default';
  labelText?: string;
  textMessageValue?: string;
  showIcon?: boolean = true;
  placeholder?: string = '';
  suffixIcon?: 'search' | 'down' | string = null;
  prefixIcon?: 'search' | 'down' | string = null;
  prefixTemplate?: string | TemplateRef<any>;
  suffixTemplate?: string | TemplateRef<any>;
  autoSize?: { [key: string]: number } = {minRows: 4, maxRows: 4};
  rows?: number;
  disable?: boolean;
  autofocus?: 'autofocus' | null; // Chưa hoạt động
  showFlexEnd?: boolean = true;
  isDatepicker?: boolean = false;
  dateConfig?: any;
  showError?: boolean = false;
  attrType?: string = 'text';
  appSpecialInput?: string;
}

@Component({
  selector: 'vnpt-input-text',
  templateUrl: './vnpt-input-text.component.html',
  styleUrls: ['../../../core/global-style/_input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VnptInputTextComponent),
    }
  ]
})
export class VnptInputTextComponent implements OnInit, ControlValueAccessor, OnChanges { // , Validator {
  @Input() vnptConfig: ModelInput = new ModelInput();
  @Input() vnptLabelText: string;
  @Input() vnptPrefixIcon: 'search' | 'calendar' | 'user';
  @Input() vnptSuffixIcon: 'down' | 'eye' | 'key';
  @Input() vnptPrefixTemplate: string | TemplateRef<any>;
  @Input() vnptSuffixTemplate: string | TemplateRef<any>;
  @Input() vnptPlaceholderText = '';
  @Input() @InputBoolean() vnptDisable = false;
  @Input() vnptAutofocus = false;
  @Input() vnptType: 'default' | 'warning' | 'error' | 'success';
  @Input() @InputBoolean() vnptIsTextAre = false;
  @Input() vnptShowFlexEnd = true;
  @Input()  @InputBoolean() vnptIsDatepicker = false;
  @Input() vnptDatepickerConfig: any;
  @Input() vnptErrorDefs: {errorName: string, errorDescription: string}[];
  @Input() vnptShowIconMessage = true;
  @Input() vnptShowError = false;
  @Input() vnptErrors: any;
  @Input() vnptMaxLength: number = 10000000000000;
  @Input() vnptMinLength: number = 0;
  @Input() @InputBoolean() vnptRequired = false;
  @Input() @InputBoolean() vnptReadOnly = false;
  @Input() vnptAutocomplete: NzAutocompleteComponent;
  @Input() vnptAttrType: string = 'text';
  @Input() vnptAppSpecialInput: string;

  @Output() vnptBlur: EventEmitter<any> = new EventEmitter<any>();

  inputGroupClass = 'input__group';
  inputGroupGroupClass = 'input__group--group';
  inputTextClass = 'input__text';
  inputMessageClass = 'input__message';

  classGroup = {
    noIcon: 'input__group--no-icon',
    icon: 'input__group--icon'
  };

  classGroupGroup = {
    default: 'input__group--group--default',
    warning: 'input__group--group--warning',
    error: 'input__group--group--error',
    success: 'input__group--group--success',
  };

  classInput = {
    default: 'input__text--default',
    warning: 'input__text--warning',
    error: 'input__text--error',
    success: 'input__text--success'
  };

  classMessage = {
    default: 'input__message--default',
    warning: 'input__message--warning',
    error: 'input__message--error',
    success: 'input__message--success'
  };

  classIcon = {
    warning: 'warning',
    error: 'warning',
    success: 'check-circle'
  };

  onTouched: () => void = noop;
  onChange: (_: any) => void = noop;
  ngControl?: NgControl;
  iconType: string;
  value: any;
  bsDatepicker = 'bsDatepicker';

  constructor(
    private inj: Injector
  ) {
    this.inputGroupClass = 'input__group' + ' ';
    this.inputGroupGroupClass = 'input__group--group' + ' ';
    this.inputTextClass = 'input__text' + ' ';
    this.inputMessageClass = 'input__message ' + ' ';
  }

  ngOnInit() {
    this.ngControl = this.inj.get(NgControl);
  }

  ngOnChanges() {
    this.inputGroupClass = 'input__group' + ' ';
    this.inputGroupGroupClass = 'input__group--group' + ' ';
    this.inputTextClass = 'input__text' + ' ';
    this.inputMessageClass = 'input__message ' + ' ';
    this.setMbConfig();
    this.configInput();
    this.setErrorMessage();
  }

  setMbConfig() {
    if (this.vnptType) {
      this.vnptConfig.type = this.vnptType;
    }
    if (this.vnptLabelText) {
      this.vnptConfig.labelText = this.vnptLabelText;
    }
    if (this.vnptPlaceholderText) {
      this.vnptConfig.placeholder = this.vnptPlaceholderText;
    }
    this.vnptConfig.disable = this.vnptDisable;

    if (this.vnptPrefixIcon) {
      this.vnptConfig.groupType = 'icon';
      this.vnptConfig.prefixIcon = this.vnptPrefixIcon;
    }
    if (this.vnptPrefixTemplate) {
      this.vnptConfig.groupType = 'icon';
      this.vnptConfig.prefixTemplate = this.vnptPrefixTemplate;
    }
    if (this.vnptSuffixIcon) {
      this.vnptConfig.groupType = 'icon';
      this.vnptConfig.suffixIcon = this.vnptSuffixIcon;
    }
    if (this.vnptSuffixTemplate) {
      this.vnptConfig.groupType = 'icon';
      this.vnptConfig.suffixTemplate = this.vnptSuffixTemplate;
    }
    if (this.vnptAutofocus) {
      this.vnptConfig.autofocus = 'autofocus';
    }
    if (this.vnptIsTextAre) {
      this.vnptConfig.groupType = 'textarea';
    }
    if (this.vnptAttrType) {
      this.vnptConfig.attrType = this.vnptAttrType;
    }
    if (this.vnptAppSpecialInput) {
      this.vnptConfig.appSpecialInput = this.vnptAppSpecialInput;
    }
    if (!this.vnptShowFlexEnd) {
      this.vnptConfig.showFlexEnd = this.vnptShowFlexEnd;
    }
    if (this.vnptIsDatepicker) {
      this.vnptConfig.isDatepicker = this.vnptIsDatepicker;
    }
    this.vnptConfig.showError = this.vnptShowError;
    this.vnptConfig.showIcon = this.vnptShowIconMessage;
    if (this.vnptDatepickerConfig) {
      this.vnptConfig.dateConfig = this.vnptDatepickerConfig;
    } else {
      this.vnptConfig.dateConfig = {
        dateInputFormat: 'DD/MM/YYYY',  returnFocusToInput: true}
    }
  }

  configInput() {
    if (this.vnptConfig?.groupType === 'icon') {
      this.inputGroupClass += this.classGroup.icon;
    } else {
      this.inputGroupClass += this.classGroup.noIcon;
    }
    switch (this.vnptConfig?.type) {
      case 'default':
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputTextClass += this.classInput.default;
        this.inputMessageClass += this.classMessage.default;
        break;
      case 'warning':
        this.inputGroupGroupClass += this.classGroupGroup.warning;
        this.inputTextClass += this.classInput.warning;
        this.inputMessageClass += this.classMessage.warning;
        this.iconType = this.classIcon.warning;
        break;
      case 'error':
        this.inputGroupGroupClass += this.classGroupGroup.error;
        this.inputTextClass += this.classInput.error;
        this.inputMessageClass += this.classMessage.error;
        this.iconType = this.classIcon.error;
        break;
      case 'success':
        this.inputGroupGroupClass += this.classGroupGroup.success;
        this.inputTextClass += this.classInput.success;
        this.inputMessageClass += this.classMessage.success;
        this.iconType = this.classIcon.success;
        break;
      default:
        this.inputGroupGroupClass += this.classGroupGroup.default;
        this.inputTextClass += this.classInput.default;
        this.inputMessageClass += this.classMessage.default;
        break;
    }
  }

  setErrorMessage() {
    if (this.vnptErrors) {
      for(let error of this.vnptErrorDefs) {
        let key = error.errorName;
        if (this.vnptErrors[key]) {
          this.vnptConfig.textMessageValue = error.errorDescription;
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.vnptDisable = isDisabled;
    this.vnptConfig.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.vnptIsDatepicker && this.value) {
      this.value = new Date(this.value);
    }
    this.onChange(this.value);
  }

  onInputChange($event: any) {
    this.onChange($event);
  }

  inputBlur($event: any) {
    this.onTouched();
    this.vnptBlur.emit($event);
  }

  /*registerOnValidatorChange(fn: () => void): void {
    this.onChange(fn);
  }

  validate(control: FormControl) {
    return null;
  }*/


}
