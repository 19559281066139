import { ChangeDetectorRef, EmbeddedViewRef, Pipe, PipeTransform, Type } from '@angular/core';

// Omit the first argument from a function type
type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R ? (...args: P) => R : never;

// Extract the first element from a tuple type
type First<T> = T extends [infer U, ...any[]] ? U : any;

// Create a tuple of function arguments, excluding the first argument
type TailArguments<F> = F extends (...args: any) => any ? Parameters<OmitFirstArg<F>> : never;

@Pipe({
  name: 'ngGenericPipe',
  pure: true
})
export class NgGenericPipe implements PipeTransform {
  private context: any;

  constructor(cdRef: ChangeDetectorRef) {
    // Retrieve component instance (this is a workaround)
    this.context = (cdRef as EmbeddedViewRef<Type<any>>).context;
  }

  public transform<T, K extends (...args: any) => ReturnType<K>>(
    headArgument: First<Parameters<K>>,
    fnReference: K,
    ...tailArguments: TailArguments<K>
  ): ReturnType<K> {
    return fnReference.apply(this.context, [headArgument, ...tailArguments]);
  }
}
