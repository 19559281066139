import {Component, Input, OnInit} from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'vnpt-button',
  templateUrl: './vnpt-button.component.html',
  styleUrls: ['../../../core/global-style/_button.scss']
})
export class VnptButtonComponent implements OnInit {
  @Input() vnptButtonType: 'BORDER' | 'NO_BORDER' | 'ONLY_ICON' = 'BORDER';
  @Input() vnptButtonSize: 'LARGE' | 'MEDIUM' | 'SMALL' | 'VERY_LARGE' = 'MEDIUM';
  @Input() vnptType: 'SUBMIT' | 'BUTTON' = 'BUTTON';
  @Input() vnptButtonBgColor = '#fff';
  @Input() vnptButtonBorderColor = '#A0A3BD';
  @Input() vnptButtonTextColor = '#6E7191';
  @Input() vnptButtonShowVerticalDash = false;
  @Input() vnptButtonVerticalDashColor = '#A0A3BD';
  @Input() vnptIsDisable = false;
  @Input() vnptPrefixIcon: null | string;
  @Input() vnptButtonText: string;
  @Input() vnptSuffixIcon: null | string;
  @Input() vnptButtonAvailableStyle: 'PRIMARY' | 'DANGER' | 'DEFAULT';
  @Input() vnptButtonIsLoading = false;
  @Input() vnptButtonIconType : 'ZORRO' | 'AWESOME' = 'ZORRO';

  vnptButtonClass = 'button__class';
  vnptStyle: any;
  styleDash: any;

  constructor() {
  }

  ngOnInit(): void {
    this.vnptButtonClass = 'button__class';
    if (this.vnptButtonType === 'BORDER') {
      this.vnptButtonClass += ' ' + 'button__class--border';
    } else {
      this.vnptButtonClass += ' ' + 'button__class--no-border';
    }
    if (this.vnptButtonShowVerticalDash) {
      this.vnptButtonClass += ' ' + 'button__class--show-dash';
    }
    if (this.vnptButtonAvailableStyle === 'PRIMARY'
      || this.vnptButtonAvailableStyle === 'DANGER'
      || this.vnptButtonAvailableStyle === 'DEFAULT'
    ) {
      this.vnptButtonClass = 'button__class button__class--no-border';
      this.vnptButtonTextColor = '#fff';
      this.vnptButtonShowVerticalDash = false;
      this.vnptButtonSize = 'MEDIUM';
    }
    if (this.vnptButtonAvailableStyle === 'PRIMARY') {

      this.vnptButtonBgColor = '#141ED2';
    }
    if (this.vnptButtonAvailableStyle === 'DANGER') {
      this.vnptButtonBgColor = '#C30052';

    }
    if (this.vnptButtonAvailableStyle === 'DEFAULT') {
      this.vnptButtonClass = 'button__class button__class--border';
      this.vnptButtonBgColor = '#ffffff';
      this.vnptButtonBorderColor = '#A0A3BD';
      this.vnptButtonTextColor = '#6E7191';
    }
    this.vnptButtonClass += ' ' + 'button__class--' + this.vnptButtonSize.toLowerCase();
    const bgColor = `${this.vnptButtonBgColor}!important`;
    const borderColor = `${this.vnptButtonBorderColor}!important`;
    const textColor = `${this.vnptButtonTextColor}!important`;
    const verticalDash = `${this.vnptButtonVerticalDashColor}!important`;
    const heightVertical = this.vnptButtonSize === 'LARGE' ? '40px' : this.vnptButtonSize === 'MEDIUM' ? '32px' : '24px';
    this.vnptStyle = {background: bgColor, 'border-color': borderColor, color: textColor};
    this.styleDash = {'border-color': verticalDash, height: heightVertical};
  }

}
