import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {VertifyQuery} from './slider-captcha.component';
import {BaseService} from '../../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SliderCaptchaService extends BaseService{
  // getHttpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json'
  //   }),
  //   withCredentials: true
  // };

  getAuthImage(url: string, username:string): Observable<any> {
    return this.get(url, {params: {'username': username}})
      .pipe(
        tap( _ => console.log('slide_captcha: ' + url))
      );
  }

  vertifyAuthImage(url: string, query: VertifyQuery): Observable<any> {
    return this.post(url, query)
      .pipe(
        tap( _ => console.log('slide_captcha: ' + url))
      );
  }
}
