import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-default-error-page',
  templateUrl: './default-error-page.component.html',
  styleUrls: ['./default-error-page.component.scss']
})
export class DefaultErrorPageComponent implements OnInit {
  readonly ERROR_PAGES = {
    "404": {
      code: 404,
      message: "Oh! Bạn xem như đã đi lạc",
      subMessage: "Trang bạn đang tìm không tồn tại",
    },
    "403": {
      code: 403,
      message: "Oh! Bạn chưa có quyền thực hiện chức năng này",
      subMessage: "Vui lòng liên hệ với admin hệ thống",
    },
    "500": {
      code: 500,
      message: "Trục trặc hệ thống",
      subMessage: "Vui lòng thử lại hoặc liên hệ với admin hệ thống",
    }
  };

  code = 200;
  constructor(
    private router : Router,
    private activeRoute: ActivatedRoute
  ) {
    this.code = activeRoute.snapshot.data['code']
  }

  ngOnInit(): void {
  }

  backHome() {
    this.router.navigate(['/']).then();
  }
}
