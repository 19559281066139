import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages.routing.module';
import {AuthGuard} from "../core/guards/auth.guard";
import {LoginGuard} from "../core/guards/login.guard";
import {AccessMenuGuard} from '../core/guards/access-menu.guard';

@NgModule({
  imports: [PagesRoutingModule],
  providers: [AuthGuard, LoginGuard, AccessMenuGuard ],
  declarations: [
  ]
})
export class PagesModule {}
