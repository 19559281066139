import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './service/auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '../../core/services/storage.service';
import {Router} from '@angular/router';
import {STORAGE_NAME} from '../../core/constant/system.constants';
import {RecaptchaComponent, RecaptchaErrorParameters} from 'ng-recaptcha';
import {ControlInput, SliderCaptchaComponent, VertifyQuery} from '../../shared/component/slider-captcha/slider-captcha.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {UrlConstant} from './constant/url.class';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  passwordVisible = false;
  isLoading = false;
  inValidCaptcha = false;
  inValidLogin = false;
  captchaResponse: any;
  numberLoginFailed = StorageService.get(STORAGE_NAME.NUMBER_LOGIN_FAILED) ? 0 : StorageService.get(STORAGE_NAME.NUMBER_LOGIN_FAILED);
  validateForm!: FormGroup;
  mobileWidth = 993;
  isMobile: any;
  messageError: string;
  @ViewChild('captchaElem') captchaElem: RecaptchaComponent;

  @ViewChild(SliderCaptchaComponent, {static: true})
  slide: SliderCaptchaComponent;
  public controlInput: ControlInput;
  private query: VertifyQuery;

  private captchaId;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translate: TranslateService,

    private modalService: NzModalService,
    private fb: FormBuilder,
  ) {
    this.controlInput = new ControlInput(
      UrlConstant.API_VERSION + '/slider-captcha/get',
      UrlConstant.API_VERSION  + '/slider-captcha/vertify',
      false
    );
    if (window.innerWidth < this.mobileWidth) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
      recaptcha: ['']
    });
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.login();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  public resolved(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  login() {
    this.isLoading = true;
    this.inValidLogin = false;
    this.messageError = null;
    let body: { [key: string]: string } = {};
    body.username = this.validateForm.get('username').value;
    body.password = this.validateForm.get('password').value;
    this.authService.login(body).subscribe(res => {
      if (res && res.access_token != '') {
        this.authService.saveStorage(res);
        this.router.navigate(['']);
      } else {
        this.messageError = "Hệ thống đang bận. Vui lòng thử lại sau.";
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      switch (error.messageCode) {
        case 'USER_NOT_EXIST':
          this.messageError = "Tài khoản không tồn tại";
          break;

        case 'WRONG_PASSWORD':
          this.messageError = "Mật khẩu không đúng.";
          if (error.data?.countTry < 7) {
            const countTry = error.data?.countTry ? ("Bạn còn " + error.data.countTry + " lần thử.") : "";
            this.captchaId = error.data?.captchaId;
            this.messageError += countTry;
          }
          break;
        case 'USER_LOCKED':
          this.messageError = 'Tài khoản đã bị khóa ';
          const lockTime = (error.data?.lock && error.data?.lockLimitRange)  ? (new Date(error.data.lock).getTime() + 60*(error.data.lockLimitRange + 1)*1000) : null;
          this.messageError += lockTime ? "đến " + moment(lockTime).format("hh:mm") : '';
          break;
        case 'REQUIRED_CAPTCHA':
          console.log("Required captcha")
          this.showCaptcha = true;
          const modal = this.modalService.create({
            nzTitle: 'Xác minh',
            nzFooter: null,
            nzCentered: true,
            nzWidth:'auto',
            nzContent: SliderCaptchaComponent,
            nzComponentParams: {
              controlInput: this.controlInput,
              username: this.validateForm.get('username').value
            }
          });
          modal.afterClose.subscribe(success => {
            if (success) {
              this.login();
            }
          });
          break;
        default:
          this.messageError = error?.message?? "Hệ thống đang bận. Vui lòng thử lại sau.";
          break;
      }
    });
  }

  checkShowRecaptcha() {
    // this.inValidLogin = true;
    // this.inValidCaptcha = false;
    // this.isLoading = false;
    // StorageService.set(STORAGE_NAME.NUMBER_LOGIN_FAILED, this.numberLoginFailed);
    // if (this.numberLoginFailed > 4) {
    //   this.validateForm.controls.recaptcha.addValidators(Validators.required);
    //   this.captchaElem?.reset();
    // }
  }

  showCaptcha

  successMatch( query: VertifyQuery) {
    console.log(query);
    this.query = query;
  }

}
