import { Pipe, PipeTransform } from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzBytesPipe } from 'ng-zorro-antd/pipes';
import {environment} from "../../../environments/environment";
import {UrlConstant} from "../../shared/constant/url.class";
import {DateFormatPipe} from "../../shared/pipes/format-date.pipe";

export enum DateFormat {
  Date = 'dd/MM/yyyy',
  DateHour = 'dd/MM/yyyy HH',
  DateTime = 'dd/MM/yyyy HH:mm',
}

export enum MapKeyType {
  String,
  Number,
  Boolean
}

export interface MapItem {
  label: string;
  value: NzSafeAny;
}

@Pipe({
  name: 'map',
})
export class CoreMapPipe implements PipeTransform {
  readonly baseUrl = environment.backend.baseUrl + UrlConstant.API_VERSION;

  private datePipe: DateFormatPipe = new DateFormatPipe();
  private bytesPipe: NzBytesPipe = new NzBytesPipe();

  static transformMapToArray(data: NzSafeAny, mapKeyType: MapKeyType = MapKeyType.Number): MapItem[] {
    return Object.keys(data || {}).map(key => {
      let value: NzSafeAny;
      switch (mapKeyType) {
        case MapKeyType.Number:
          value = Number(key) ?? 0;
          break;
        case MapKeyType.Boolean:
          value = key === 'true';
          break;
        case MapKeyType.String:
        default:
          value = key;
          break;
      }
      return {value, label: data[key]};
    });
  }

  transform(value: NzSafeAny, arg?: NzSafeAny): NzSafeAny {
    if (arg === undefined) {
      return value;
    }
    let type: string = arg;
    let param = '';

    if (arg.indexOf(':') !== -1) {
      type = arg.substring(0, arg.indexOf(':'));
      param = arg.substring(arg.indexOf(':') + 1, arg.length);
    }

    switch (type) {
      case 'date':
        return this.datePipe.transform(value, param) ?? "-";
      case 'bytes':
        return this.bytesPipe.transform(value) ?? "-";
      case 'yn':
        return (value && value == 1) ? "Có" : "Không";
      case 'imageUrl':
        return (value) ? this.baseUrl + value : "";
      case 'default-blank':
        return (value) ?  value : "-";
      default:
        // return (this.mapObj[type] ? this.mapObj[type][value] : '');
        return "-";
    }
  }
}
