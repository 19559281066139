import {TemplateRef} from "@angular/core";
import {NzTableSize} from "ng-zorro-antd/table";

export class VnptTableHeader {
  title: string;                   // Tiêu đề
  titleTrans?: string;             // Tiêu đề được dịch
  field?: string;                  // Tên trường dữ liệu
  pipe?: string;                    // PIPE
  // showSort?: boolean;               // Hiển thị sort
  // sortDir?: undefined | 'asc' | 'desc';  // Loại sort
  width?: number;                    // Width
  thTemplate?: TemplateRef<any> | string;    // th TemplateRef
  tdTemplate?: TemplateRef<any> | string;    // td TemplateRef
  fixed?: boolean;                  // fixed
  fixedDir?: 'left' | 'right';        // fixed Bên trái hoặc phải (liên tục)
  needEllipsis?: boolean;           // truncate
  needBreakword?: boolean;           // truncate
  tdClassList?: string[];           // class thẻ TD
  thClassList?: string[];           // class thẻ TH
  show?: boolean;                   // hiển thị cột mặc định là true
}

export class VnptTableConfig {
  needScroll?: boolean;
  size?: 'small'| 'middle' | 'default' = 'default';
  showFrontPagination?: boolean = false;
  showCheckbox?: boolean = false;           // Mặc định false, [checkCashArrayFromComment] = "cashArray" - cần có thuộc tính ID
  pageIndex: number;
  pageSize: number;
  total: number;
  loading: boolean;
  headers: VnptTableHeader[];
  showId?: boolean = true;
  bordered?: boolean = true;
  showSizeChanger?: boolean = false;
  pageSizeOptions?: number[] = [10, 50, 100];
  activeRow?: boolean = false
}

export abstract class VnptTableComponentToken {
  tableSize!: NzTableSize;
  tableConfig!: VnptTableConfig;

  abstract tableChangeDetection(): void;
}

export interface SortFile {
  fileName: string;
  sortDir: undefined | 'desc' | 'asc'
}
