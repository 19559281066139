import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {ToastrService} from 'ngx-toastr';
import {ImageUtils} from '../../utils/image-utils.class';
import {Observable, Observer} from 'rxjs';

export type ImageType = "image/png"|"image/jpeg"|"image/jpg"|"image/ico"|"image/svg+xml";

@Component({
  selector: 'app-upload-image-simple',
  templateUrl: './upload-image-simple.component.html',
  styleUrls: ['./upload-image-simple.component.scss']
})
export class UploadImageSimpleComponent implements OnInit {
  @Input() accept: ImageType[] = ["image/png", "image/jpeg", "image/png", "image/ico", "image/svg+xml"];
  @Input() minSize:number = 0; //MB
  @Input() maxSize:number = 20; //MB
  @Input() maxWidth: number = 1000000; //px
  @Input() maxHeight: number = 1000000; //px
  @Input() minWidth: number = 10; //px
  @Input() minHeight: number = 10; //px
  @Input() stylePreview = {width: '500px', height: '100%'}
  @Input() imageUrl: string;

  fileList: NzUploadFile[] = [];
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  beforeUpload = (file: NzUploadFile) => {
      const isImage = this.accept.includes(file.type as unknown as ImageType);
      if (!isImage) {
        this.toastrService.warning('Chỉ có thể upload ảnh!', null, {positionClass: 'toast-top-center'});
        return false;
      }
      const isMinImgSize = file.size! / 1024 / 1024 >= this.minSize;
      const isMaxImgSize = file.size! / 1024 / 1024 <= this.maxSize;
      if (!isMinImgSize) {
        this.toastrService.warning(`Dung lượng ảnh quá nhỏ. Yêu cầu > ${this.minSize} MB`, null, {positionClass: 'toast-top-center'});
        return false;
      }

      if (!isMaxImgSize) {
        this.toastrService.warning(`Dung lượng ảnh quá lớn. Yêu cầu < ${this.maxSize} MB`, null, {positionClass: 'toast-top-center'});
        return false;
      }

      let isDimension = false;
      ImageUtils.checkImageDimension(file as unknown as File, this.minWidth, this.maxWidth, this.minHeight, this.maxHeight).then(dimensionRes => {
        isDimension = dimensionRes;
        if (!dimensionRes) {
          this.toastrService.warning(
            `Kích thước ảnh yêu cầu ${this.minWidth} x ${this.minHeight} - ${this.maxWidth} x ${this.maxHeight}`,
            null,
            {positionClass: 'toast-top-center'}
          );
          return false;
        }
        this.handleUpload(file);
      });

      return false;
  };

  handleUpload = (file: NzUploadFile) => {
    this.remove();
    this.fileList = [file];
    this.uploadComplete.emit(this.fileList[0]);
    this.getBase64(file as unknown as File, (img: string) => {
      this.imageUrl = img;
    });
  }
  remove = () => this.fileList = [];

  getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }
}
