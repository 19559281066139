import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vnpt-tree-input',
  templateUrl: './vnpt-tree-input.component.html',
  styleUrls: ['../../../core/global-style/_select.scss', './vnpt-tree-input.component.scss']
})
export class VnptTreeInputComponent implements OnInit {

  @Input() vnptLabelText: string;
  @Input() vnptPlaceholderText = '';
  @Input() vnptDataTree: any = [];
  @Input() expandKeys: any = [];
  @Input() vnptMultiple: boolean = false;
  @Input() vnptCheckable: boolean = false;
  @Input() vnptShowSearch: boolean = false;

  value?: string;

  constructor() {
  }

  ngOnInit(): void {
  }


}
