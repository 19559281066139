import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import { FormatCurrencyPipe } from '../../pipes/format-currency.pipe';
import * as moment from 'moment';

@Component({
  selector: 'vnpt-text-label',
  templateUrl: './vnpt-text-label.component.html',
  styleUrls: ['../../../core/global-style/_text-label.scss']
})
export class VnptTextLabelComponent implements OnInit, OnChanges {
  @Input() vnptValueType: 'string' | 'number' | 'date' | 'currency' | any = 'string';
  @Input() vnptLabelText: any;
  @Input() vnptLabelValue: any;
  @Input() vnptShowEdit = false;
  @Input() vnptDateFormat: string = '';
  @Input() vnptShowLabelText = true;
  @Input() vnptShowLabelValue = true;
  @Input() vnptPlaceholderText = '';
  @Input() vptPrefixIcon: 'search' | 'calendar' | 'user' | string;
  @Input() vnptSuffixIcon: 'down' | 'eye' | 'key' | string;

  valueShow: any;
  dateFormatShow: any;

  constructor(
    private datePipe: DatePipe,
    private currencyFormat: FormatCurrencyPipe
  ) {
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.configValue();
  }

  configValue() {
    this.dateFormatShow = this.vnptDateFormat;
    if (this.vnptDateFormat === '') {
      this.dateFormatShow = this.vnptShowEdit ? 'DD/MM/YYYY' : 'dd/MM/yyyy';
    }
    this.valueShow = this.vnptLabelValue;
    if (this.vnptValueType === 'date') {
      this.valueShow = this.vnptShowEdit ? moment(this.vnptLabelValue, this.dateFormatShow).toDate() : this.vnptLabelValue;
    }
    if (this.vnptValueType === 'currency') {
      this.valueShow = this.vnptShowEdit ? this.vnptLabelValue : this.currencyFormat.transform(this.vnptLabelValue);
    }
  }

}
