import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export class ContextMenuModelInput {
  id: any;
  positionX: any = 0;
  positionY: any = 0;
  isEdit = false;
  isView = false;
  isDelete = false;
  data?: any;
  actions?: ContextMenuAction[];
  isExtend: IsExtend = new IsExtend();
}

export class IsExtend {
  isView = true;
  isEdit = true;
  isDelete = true;
  isCopy = true;
}

export class ContextMenuAction {
  isEnable: boolean;
  actionName: any;
  action: any;
  icon: any;
  isNzPopConfirm?: boolean;
  nzTitle?: any;
}

export class EventEmitDataModel {
  action: 'VIEW' | 'EDIT' | 'COPY' | 'DELETE' | string;
  id?: any;
  data?: any;
  isVisible?: boolean;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})

export class ContextMenuComponent implements OnInit {
  @Input() dataInput: ContextMenuModelInput;
  @Output() eventEmitData: EventEmitter<EventEmitDataModel> = new EventEmitter<EventEmitDataModel>();

  isVisible = false;

  constructor(
  ) { }

  ngOnInit(): void {
    if (this.dataInput != null && this.dataInput.id != null) {
      this.isVisible = true;
    } else {
      console.error('Không xác định được đối tượng!');
    }
  }

  emitEvent(action: 'VIEW' | 'EDIT' | 'COPY' | 'DELETE' | string, isDoAction: boolean) {
    if (isDoAction) {
      const obj = new EventEmitDataModel();
      obj.action = action;
      obj.id = this.dataInput.id;
      obj.isVisible = false;
      obj.data = this.dataInput.data;
      this.eventEmitData.emit(obj);
    } else {
      return false;
    }
  }

  returnNone() {
    return false;
  }
}
