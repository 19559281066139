import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class BaseService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * handleError
   */
  public handleError(error: any) {
    return throwError(error.error);
  }

  /**
   * make get request
   */
  public get(endpointUrl: string, options?: any, serviceName?: string): Observable<any> {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return this.httpClient.get(urlPath, options).pipe(
    );
  }

  public getPath(endpointUrl: string, serviceName?: string): Observable<any> {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return this.httpClient.get(urlPath, {
      responseType: 'blob',
    }).pipe(
    );
  }


  /**
   * make get request with async
   */
  public async getWithAsync(endpointUrl: string, options?: any, serviceName?: string) {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return await this.httpClient.get(urlPath, options).toPromise();
  }


  /**
   * make post request
   */
  public post(endpointUrl: string, data?: any, options?: any, serviceName?: string): Observable<any> {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return this.httpClient.post(urlPath, data, options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * make put request
   */
  public put(endpointUrl: string, data?: any, serviceName?: string): Observable<any> {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return this.httpClient.put(urlPath, data).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * make delete request
   */
  public delete(endpointUrl: string, options?: any, serviceName?: string): Observable<any> {
    let urlPath = this.getBaseUrl(serviceName) + endpointUrl;
    return this.httpClient.delete(urlPath, options).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * make post request for file
   */
  public postRequestFile(endpointUrl: string, data?: any, baseUrl?: string): Observable<any> {
    let urlPath = baseUrl != null ? baseUrl : environment.backend.baseUrl + endpointUrl;
    return this.httpClient.post(urlPath, data, {responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );
  }

  public getRequestFile(endpointUrl: string, options?: any, baseUrl?: string): Observable<any> {
    let urlPath = baseUrl != null ? baseUrl : environment.backend.baseUrl + endpointUrl;
    return this.httpClient.get(urlPath, {...options, responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );
  }

  public getRequestAbsFile(endpointUrl: string, options?: any, baseUrl?: string): Observable<any> {
    let urlPath = baseUrl != null ? baseUrl : environment.backend.baseUrl + endpointUrl;
    return this.httpClient.get(urlPath, {...options, responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );
  }

  private getBaseUrl(serviceName?: string): string {
    let baseUrl = environment.backend.baseUrl;
    return baseUrl;
  }

}
