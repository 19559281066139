export class UrlConstant {
    public static readonly API_VERSION = '/api';

    public static readonly LOGIN = '/auth/login';

    public static readonly LOGOUT = '/auth/logout';

    public static readonly REFRESH_TOKEN = '/refresh-token';

  }
