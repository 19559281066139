export class ImageUtils {
  public static async getBase64ImageFromUrl(imageUrl: string) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener('load', () => {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  static checkImageDimension(file: File, minWidth:number, maxWidth: number, minHeight: number, maxHeight:number): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image(); // create image
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        // console.log( width + '    ' + height);
        window.URL.revokeObjectURL(img.src!);
        resolve(
          width >= minWidth &&
          width <= maxWidth &&
          height >= minHeight &&
          height <= maxHeight
        );
      };
    });
  }

  static getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }
}
