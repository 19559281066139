import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultErrorPageComponent} from '../core/layout/error-templates/default-error-page/default-error-page.component';

const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('./management/users/users.module').then(m => m.UsersModule),
    data: {
      pageName: 'users',
      breadcrumb: 'users'
    }
  },
  {
    path: 'voucher',
    loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule),
    data: {
      pageName: 'voucher',
      breadcrumb: 'voucher'
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard-manager/dashboard-manager.module').then(m => m.DashboardManagerModule),
    data: {
      pageName: 'dashboard.pageName.dashboardManager',
      breadcrumb: 'dashboard.breadcrumb.dashboardManager'
    }
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

  {
    path: 'roles',
    loadChildren: () => import('./management/role/role.module').then(m => m.RoleModule),
    data: {
      pageName: 'Quản lý Roles',
      breadcrumb: 'Roles'
    }
  },
  {
    path: 'permissions',
    loadChildren: () => import('./management/permission/permission.module').then(m => m.PermissionModule),
    data: {
      pageName: 'Quản lý Permission',
      breadcrumb: 'Permissions'
    }
  },
  {
    path: 'menus',
    loadChildren: () => import('./management/menu/menu.module').then(m => m.MenuModule),
    data: {
      pageName: 'Cấu hình Menu',
      breadcrumb: 'Cấu hình Menu'
    }
  },
  {
    path: 'manager-config',
    loadChildren: () => import('./manager-config-list/manager-config-list.module').then(m => m.ManagerConfigListModule),
    data: {
      pageName: '',
      breadcrumb: null
    }
  },
  {
    path: 'infomation',
    loadChildren: () => import('./infomation/infomation.module').then(m => m.InfomationModule),
    data: {
      breadcrumb: '',
    }
  },
  {
    path: 'loyalty',
    loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule),
    data: {
      breadcrumb: '',
    }
  },
  {
    path: 'report',
    loadChildren: () => import('./reports/report.module').then(m => m.ReportModule),
    data: {
      pageName: 'report',
      breadcrumb: 'report'
    }
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    data: {
      pageName: 'noti',
      breadcrumb: 'noti'
    }
  },
  { path: 'page-403', component: DefaultErrorPageComponent,
    data: {
      pageName: 'GAMI ADMIN',
      code: 403
    }
  },
  { path: 'page-404', component: DefaultErrorPageComponent,
    data: {
      pageName: 'GAMI ADMIN',
      code: 404
    }
  },
  { path: '**', redirectTo: '/page-404' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
