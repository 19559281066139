import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { AntDesignModule } from './ant-design.module';
import { TopProgressBarComponent } from '../shared/component/top-progress-bar/top-progress-bar.component';
import { MenuSearchComponent } from './layout/menu-search/menu-search.component';
import { PageNotFoundComponent } from './layout/error-templates/page-not-found/page-not-found.component';
import {CoreMapPipe} from "./pipes/map.pipe";
import {CoreSecurePipe} from "./pipes/secure.pipe";
import { DefaultErrorPageComponent } from './layout/error-templates/default-error-page/default-error-page.component';

const THIRD_MODULES = [AntDesignModule];
const COMPONENTS = [TopProgressBarComponent, HeaderComponent, LayoutComponent];
const DIRECTIVE = [];
const PIPE =[CoreMapPipe, CoreSecurePipe]
@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVE,PIPE, MenuSearchComponent, PageNotFoundComponent, DefaultErrorPageComponent],
  imports: [
    NzInputModule,
    NzSelectModule,
    NzToolTipModule,
    CommonModule,
    DragDropModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InfiniteScrollModule,
    NzMenuModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzIconModule,
    NzModalModule,
    NzMessageModule, FormsModule, ReactiveFormsModule,
    ...THIRD_MODULES,
  ],
  exports: [
    ...THIRD_MODULES, FormsModule, ReactiveFormsModule,
    ...DIRECTIVE
  ],
  providers: [
    Title,
    ...PIPE
  ]
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
}
