import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input, OnInit } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { VnptTableComponentToken, VnptTableHeader } from '../vnpt-table/vnpt-table.interfaces';
import { StringUtils } from '../../utils/string-utils.class';
import { TranslateService } from '@ngx-translate/core';
import {InputBoolean} from 'ng-zorro-antd/core/util';

@Component({
  selector: 'vnpt-table-wrap',
  templateUrl: './vnpt-table-wrap.component.html',
  styleUrls: ['./vnpt-table-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VnptTableWrapComponent implements OnInit, AfterContentInit {
  @Input() vnptTitle;
  @Input()
  @Input() @InputBoolean() vnptShowSelectColumn = true;
  columnSearch: string ;
  tableConfigVisible = false;
  @ContentChild(VnptTableComponentToken) antTableComponent!: VnptTableComponentToken;
  currentTableComponent!: VnptTableComponentToken ;

  tableHeaders: VnptTableHeader[] = [];
  tableHeadersSource: VnptTableHeader[] = [];
  copyHeader: VnptTableHeader[] = [];
  allTableFieldChecked = false;
  allTableFieldIndeterminate = false;

  constructor(private translate: TranslateService ) { }

  ngOnInit(): void {
    this.vnptTitle = this.vnptTitle ?? this.translate.instant("common.label.searchResult");
    this.translate.onLangChange.subscribe(res => this.configTableHeader())
  }

  configTableHeader() {
    this.tableHeaders.forEach(header => header.titleTrans = this.translate.instant(header.title))
    this.copyHeader.length = 0;
    this.tableHeaders.forEach(item => {
      this.copyHeader.push({...item});
    })
    this.tableHeadersSource = [...this.tableHeaders];
  }

  dropTableConfig(event: any) {
    moveItemInArray(this.tableHeaders, event.previousIndex, event.currentIndex);
    this.changeTableConfigShow();
  }

  changeTableConfigShow(): void {
    const tempArray = [...this.tableHeaders];
    const fixedLeftArray: VnptTableHeader[] = [];
    const fixedRightArray: VnptTableHeader[] = [];
    const noFixedArray: VnptTableHeader[] = [];
    tempArray.forEach(item => {
      if (item.fixed) {
        if (item.fixedDir === "left") {
          fixedLeftArray.push(item);
        } else {
          fixedRightArray.push(item)
        }
      } else {
        noFixedArray.push(item)
      }
    });
    this.currentTableComponent.tableConfig.headers = [...fixedLeftArray, ...noFixedArray, ...fixedRightArray];
    this.tableChangeDetection();
  }

  tableChangeDetection() {
    this.currentTableComponent.tableChangeDetection();
  }

  changeSignalCheck(e: boolean, item: VnptTableHeader): void {
    item.show = e;
    this.judgeAllChecked();
    this.tableChangeDetection();
  }

  judgeAllChecked(): void {
    this.allTableFieldChecked = this.tableHeaders.every(item => item.show === true);
    const allUnChecked = this.tableHeaders.every(item => !item.show);
    this.allTableFieldIndeterminate = !this.allTableFieldChecked && !allUnChecked;
  }

  reset(): void {
    this.columnSearch = null;
    this.tableHeaders = [];
    this.copyHeader.forEach(item => {
      this.tableHeaders.push({...item});
    })
    this.tableHeadersSource = [...this.tableHeaders];
    this.currentTableComponent.tableConfig.headers = [...this.tableHeaders];
    this.tableChangeDetection();
  }

  ngAfterContentInit(): void {
    this.currentTableComponent = this.antTableComponent;

    // if (this.isNormalTable) {
      this.tableHeaders = [...this.currentTableComponent.tableConfig.headers];
      this.tableHeaders.forEach(item => {
        if (item.show === undefined) {
          item.show = true;
        }
      })
    this.configTableHeader();
    this.judgeAllChecked();
    // }
  }

  changeAllTableTableConfigShow(e: boolean): void {
    if (e) {
      this.allTableFieldChecked = e;
      this.allTableFieldIndeterminate = false;
    }
    this.tableHeaders.forEach(item => item.show = e);
    this.tableHeadersSource = [...this.tableHeaders];
    this.tableChangeDetection();
  }

  searchColumn() {
    if (!StringUtils.isNullOrEmpty(this.columnSearch))
      this.tableHeaders = this.tableHeadersSource.filter(item => item.titleTrans.toLowerCase().startsWith(this.columnSearch.toLowerCase()))
    else
      this.tableHeaders = [...this.tableHeadersSource];
  }
}
