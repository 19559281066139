import {Directive, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[appSpecialInput]'
})
export class SpecialInputDirective {

  defaultText = /[`~!#$%^&*|+\=?:'<>\{\}\[\]\\]/g;

  @Input('appSpecialInput') appSpecialInputText: string;

  constructor(
    private el: NgControl
  ) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.el.control.patchValue(value.replace( this.appSpecialInputText ? new RegExp(this.appSpecialInputText) : this.defaultText, ''));
  }

}
