import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { LoadingComponent } from './component/loading/loading.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { WidthDirective } from './directive/width-directive';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UppercaseInputDirective } from './directive/uppercase-input.directive';
import { VnptInputTextComponent } from './component/vnpt-input-text/vnpt-input-text.component';
import { VnptTextLabelComponent } from './component/vnpt-text-label/vnpt-text-label.component';
import { VnptButtonComponent } from './component/vnpt-button/vnpt-button.component';
import { SelectAbleComponent } from './component/select-able/select-able.component';
import { VnptDatePickerComponent } from './component/vnpt-date-picker/vnpt-date-picker.component';
import { SelectComponent } from './component/select/select.component';
import { VnptTreeInputComponent } from './component/vnpt-tree-input/vnpt-tree-input.component';
import { NumberInputDirective } from './directive/number-input.directive';
import { InputTrimDirective } from './directive/input-trim.directive';
import { MaskDateDirective } from './directive/mask-date.directive';
import { VndFormatDirective } from './directive/vnd-format.directive';
import { UnsignedCharacterDirective } from './directive/unsigned-character.directive';
import { MaskRangeDateDirective } from './directive/mask-range-date.directive';
import { InputSpecialDirective } from './directive/input-special.directive';
import { SpecialInputDirective } from './directive/special-input.directive';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { ImagePipe } from './pipes/image.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DateFormatPipe } from './pipes/format-date.pipe';
import { FilterPipe } from './pipes/custom-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { HighlightSearch } from './pipes/high-light.pipe';
import { ContextMenuComponent } from './component/context-menu/context-menu.component';
import { ExtendFormItemComponent } from './component/extend-form-item/extend-form-item.component';
import { CoreModule } from '../core/core.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TrimDirective } from './directive/trim-input.directive';
import { NumbericDirective } from './directive/numberic.directive';
import { VnptTableComponent } from './component/vnpt-table/vnpt-table.component';
import { VnptTableWrapComponent } from './component/vnpt-table-wrap/vnpt-table-wrap.component';
import { MapPipe } from './pipes/map.pipe';
import { TableFiledPipe } from './pipes/table-filed.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SwitchCasesDirective } from './directive/switch-case.directive';
import { VnptRangDatePickerComponent } from './component/vnpt-rang-date-picker/vnpt-rang-date-picker.component';
import { SwiperModule } from 'swiper/angular';
import { VnptDateTimeWorkComponent } from './component/vnpt-date-time-work/vnpt-date-time-work.component';
import {StatusPipe} from "./pipes/status.pipe";
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {NgTemplateNameDirective} from './directive/ng-template-ref-name.directive';
import { UploadImageSimpleComponent } from './component/upload-image-simple/upload-image-simple.component';
import {SecurePipe} from "./pipes/secure.pipe";
import {SliderCaptchaComponent} from './component/slider-captcha/slider-captcha.component';
import {NumberInputPercentDirective} from "./directive/number-input-percent.directive";
import { EditorModule } from 'primeng/editor';
import {IsDecimalDirective} from './directive/is-decimal.directive';
import {NgGenericPipeModule} from './pipes/generic/ng-generic-pipe.module';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';


const PIPES = [SecurePipe, CurrencyFormatPipe, FormatCurrencyPipe, TruncatePipe, SafePipe, HighlightSearch, ImagePipe, FilterPipe, DateFormatPipe, MapPipe, TableFiledPipe, StatusPipe];

const COMPONENTS = [LoadingComponent,
  VnptInputTextComponent, VnptDatePickerComponent, VnptDateTimeWorkComponent, UploadImageSimpleComponent,
  SelectAbleComponent, VnptButtonComponent, VnptTextLabelComponent, SelectComponent, VnptTreeInputComponent, ContextMenuComponent,VnptRangDatePickerComponent,
  ExtendFormItemComponent, VnptTableComponent, VnptTableWrapComponent, SliderCaptchaComponent];

const THIRD_MODULES = [EditorModule,TranslateModule, SwiperModule, BsDatepickerModule.forRoot()];

const DIRECTIVE = [
  WidthDirective, UppercaseInputDirective,
  NumberInputDirective, InputTrimDirective, InputSpecialDirective, VndFormatDirective,
  UnsignedCharacterDirective, MaskDateDirective, MaskRangeDateDirective, SpecialInputDirective, TrimDirective, NumbericDirective,
  SwitchCasesDirective,NgTemplateNameDirective, NumberInputPercentDirective, IsDecimalDirective
];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVE, ...PIPES],
  imports: [
    CoreModule,
    NzButtonModule,
    CommonModule,
    NzTableModule,
    NzIconModule,
    NzSwitchModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    ...THIRD_MODULES,
    DragDropModule,
    NgGenericPipeModule
  ],
    exports: [...COMPONENTS, ...DIRECTIVE, ...PIPES, THIRD_MODULES, CoreModule,CommonModule, NgGenericPipeModule],
  providers: [
    DatePipe,
    FormatCurrencyPipe,
    CurrencyFormatPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {
}
