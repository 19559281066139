import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from "../../pages/login/service/auth.service";
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): any{
          if (this.authService.isAuthenticated()) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
          return true;
      }

}
