import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CurrentUserService} from '../../shared/services/current-user.service';
import {ToastrService} from 'ngx-toastr';
import {map} from 'rxjs/operators';
import {BASE_RESPONSE_STATUS, BaseResponse} from '../models/base-response';

@Injectable({
  providedIn: 'root'
})
export class AccessMenuGuard implements CanActivate {

  constructor(
    private currentUserService: CurrentUserService,
    private toastrService: ToastrService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
    return this.checkUserAccess(route.data?.canActive);
  }

  checkUserAccess(f: any[]) {
    if (!f) return true;
    return this.currentUserService.canAccessMenu(f?.join(";")).pipe(
      map((res: any) => {
        if (res && res.messageCode == BASE_RESPONSE_STATUS.OK && res.data.canActive) return true;
        else {
          this.router.navigateByUrl('/page-403').then();
          return false;
        }
      }),
    )
  }

}
