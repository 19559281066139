import { Directive, HostListener, ElementRef, Input} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appNumberic]'
})
export class NumbericDirective {

  @Input() decimals = 2;
  @Input() num = 2;

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      const regExpString =
        '^\\s*((\\d{1,' + this.num + '}(\\.\\d{0,' +
        this.decimals +
        '})?)|((\\d{1,' + this.num + '}(\\.\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue) {
    setTimeout(() => {
      const currentValue: string = this.el.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.control.patchValue(oldValue);
      }
    });
  }

  constructor(private el: NgControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.el.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.value);
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    this.run(this.el.value);
  }

}
